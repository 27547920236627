import { Box, Container, Typography, Grid2, Button } from '@mui/material';
import AItechCard from './AItechCard';
import { AI_TECH_CARD_DATA } from './ai-tech-datas';
import EastIcon from '@mui/icons-material/East';
import { SmOrDown, SmOrUp, XsOrDown } from '@ocodelib/ui-common';
import { routerPush } from '@repo-ui/router';
import { useLocale } from 'next-intl';

export default function HomeAItechIntro() {
  const locale = useLocale();
  return (
    <Box
      className="HomeAItechIntro-root"
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 8, lg: 10 },
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          minHeight: 'calc(100dvh - 130px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          className="HomeAItechIntro-titleBox"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: { xs: 400, sm: '100%' },
            mb: { xs: 2, lg: 4 },
            px: { xs: 1, md: 0 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '2.4rem', md: '2.6rem' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            AI 기술 학습하기
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: { xs: '1.1rem', lg: '1.2rem' },
              mt: 1.5,
            }}
          >
            데이터를 입력해 모델을 학습시키고, 새로운 데이터에 대한 예측을 수행하는 머신 러닝 기술
          </Typography>
        </Box>
        <SmOrUp>
          <Grid2 container columnSpacing={2.5} rowSpacing={6} mt={1}>
            {AI_TECH_CARD_DATA.map((it) => (
              <Grid2 key={it.title} size={{ xs: 12, sm: 6, md: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                  }}
                >
                  <AItechCard
                    {...it}
                    onClick={() => {
                      console.log(it.action);
                    }}
                  />
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </SmOrUp>

        <XsOrDown>
          <Grid2 container columnSpacing={2.5} rowSpacing={4} mt={1} sx={{ maxWidth: 400 }}>
            {AI_TECH_CARD_DATA.slice(0, 3).map((it) => (
              <Grid2 key={it.title} size={{ xs: 12, sm: 6, md: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                  }}
                >
                  <AItechCard
                    {...it}
                    onClick={() => {
                      console.log(it.action);
                    }}
                  />
                </Box>
              </Grid2>
            ))}
          </Grid2>
        </XsOrDown>

        {/* 더 보기 버튼 */}
        <Button
          color="inherit"
          variant="outlined"
          endIcon={<EastIcon />}
          sx={{
            mt: 8,
            border: '1px solid #76787A',
            color: '#76787A',
            borderRadius: '20px',
          }}
          onClick={() => {
            routerPush(locale, '/ai-learn/list/supervised');
          }}
        >
          더 보기
        </Button>
      </Container>
    </Box>
  );
}
