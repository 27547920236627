import { Box, ButtonBase, SxProps, Typography } from '@mui/material';
import { flatSx } from '@ocodelib/ui-common';
import { routerPush } from '@repo-ui/router';
import clsx from 'clsx';

interface Props {
  sx?: SxProps;
  className?: string;
  imgUrl: string;
  title: string;
  textColor?: string;
  desc: React.ReactNode;
  bgColor: string;
  href: string;
}

export default function MenuSmallCard(props: Props) {
  const { sx, className, imgUrl, title, bgColor, textColor, desc, href } = props;
  return (
    <ButtonBase
      component="div"
      className={clsx('MenuSmallCard-root', className)}
      onClick={() => routerPush(href)}
      sx={flatSx(
        (theme) => ({
          position: 'relative',
          background: bgColor,
          width: '100%',
          height: '100%',
          pt: { xs: 2, md: 3 },
          pb: 3,
          px: 4,
          borderRadius: '15px',
          justifyContent: 'flex-start',
          overflow: 'hidden',
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
          },
        }),
        sx,
      )}
    >
      {/* img */}
      <Box
        className="MenuSmallCard-imgBox"
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 10,
          width: { xs: 100, md: '45%' },
          height: { xs: 100, md: 'auto' },
          // width: { xs: 100, lg: 162 },
          // height: { xs: 100, lg: 162 },
          '& img': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={imgUrl} alt="" />
      </Box>

      {/* text */}
      <Box className="MenuSmallCard-textBox" sx={{ width: '100%', zIndex: 999 }}>
        <Typography
          sx={{
            fontSize: { xs: '1.7rem', md: '1.8rem' },
            fontWeight: '500',
            whiteSpace: 'pre-line',
            color: textColor,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'pre-line',
            color: textColor,
            fontWeight: 300,
            fontSize: '0.95rem',
          }}
        >
          {desc}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
